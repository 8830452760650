/*
  Action types
 */
export const FETCH_API_TOKEN_SUCCESS = "FETCH_API_TOKEN_SUCCESS";
export const FETCH_API_TOKEN = "FETCH_API_TOKEN";
export const COLLECTIONS_REQUEST = "COLLECTIONS_REQUEST";
export const COLLECTIONS_SUCCESS = "COLLECTIONS_SUCCESS";
export const COLLECTIONS_FAILURE = "COLLECTIONS_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SEARCH_VALUE_CHANGE = "SEARCH_VALUE_CHANGE";
