import { productionIds } from "../../services/global-vars";

export const heroBerkeley = {
  title: "Berkeley Folk Festival",
  subTitle: "Summer of love - collection description here",
  collectionId: productionIds.berkeley,
  heroImage: "alice-at-the-greek-1440x600.png",
};

export const heroFava = {
  title: "Commedia dell'Arte",
  subTitle: "The Masks of Antonio Fava",
  collectionId: productionIds.fava,
  heroImage: "hero-fava-1440x600.jpg",
};

export const heroWWII = {
  title: "World War II Poster Collection at Northwestern University Library",
  subTitle:
    "View more than 300 World War II-era posters issued by various U.S. government agencies.",
  collectionId: productionIds.wwII,
  heroImage: "hero-wwII-1440x600.jpg",
};

export const heroWWII_2 = {
  title: "World War II Poster Collection at Northwestern University Library",
  subTitle:
    "View more than 300 World War II-era posters issued by various U.S. government agencies.",
  collectionId: productionIds.wwII,
  heroImage: "hero-wwII-1440x600-2.jpg",
};

export const heroWPA = {
  title: "WPA Digital Collection, 1935-1943",
  subTitle:
    "Images taken from: Narrative report, July 1935 to April 1936. Illinois: Works Progress Administration, Illinois, Women and Professional Division, 1936.",
  collectionId: productionIds.wpa,
  heroImage: "hero-wpa-children-1440x600.jpg",
};

export const heroSecondaryData = {
  title: 'James E. "Jimmy" Johnson (1879-1942) Scrapbooks and Miscellanea',
  subTitle:
    'Browse our collection of James E. "Jimmy" Johnson Scrapbooks from the University Archives',
  collectionId: productionIds.jimmyJohnson,
  heroImage: "hero-section-northwestern-football-1440x540.jpg",
};
